<template>
    <div>
        <v-banner v-if="showBanner" class="error-banner" lines="one" text="" :stacked="false">
        <v-banner-text>
            This application is an administrative tool intended for use by authorized personnel only. Please ensure you have carefully reviewed the documentation and fully understand the potential consequences of your actions.
        </v-banner-text>
        <template  v-slot:actions>
            <v-btn @click="showBanner = false">I understand</v-btn>
        </template>        
        </v-banner>
        <v-card-title>
            <v-btn data-qa="generate-license" color="primary" dark @click="newLicense" >Generate new license</v-btn>
            <v-btn data-qa="refresh-licenses" icon text color="primary" dark @click="refresh" ><v-icon>refresh</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-text-field
            data-qa="license-search-field"
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            ></v-text-field>
        </v-card-title>


        <v-dialog v-model="newLicenseModal" max-width="500px">
            <v-card>
                <v-card-text>
                Generated new licenses 
                    <ul data-qa="new-licenses-list" id="newLicensesList" style="max-height: 300px; overflow: auto">
                        <li :key="latestLicense.activationKey" data-qa="new-licenses-list-item" v-for="latestLicense in latestLicenses">
                            {{ latestLicense.activationKey }}
                        </li>
                    </ul>
                </v-card-text>
                <v-card-actions>
                    <v-btn data-qa="confirm-license" @click="newLicenseModal = false">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
  
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field data-qa="no-field" type="number" placeholder="1" v-if="newLicenseForm" v-model="editedItem.copies" label="Copies"></v-text-field>
                    <v-text-field data-qa="serial-field" v-model="editedItem.serialNumber" label="Serial no."></v-text-field>
                    <v-text-field data-qa="customer-field" v-model="editedItem.clientName" label="Customer"></v-text-field>
                    <v-text-field data-qa="notes-field" v-model="editedItem.notes" label="Notes"></v-text-field>
                    <v-switch     data-qa="preactivation-mode" :readonly="!newLicenseForm" v-model="editedItem.preactivationMode" label="Absolute range"></v-switch>
                    <v-text-field data-qa="vpn-preactivation-period" :readonly="!newLicenseForm" v-if="editedItem.preactivationMode == 0" v-model="editedItem.vpnValidityMonths" label="Bundled VPN duration (months -- 31days)"></v-text-field>
                    <v-switch     data-qa="vpn-accounting-disabled" :readonly="!newLicenseForm" v-model="editedItem.vpnAccountingDisabled" label="VPN Credits Accounting Disabled"></v-switch>
                    <v-text-field data-qa="vpn-start-date-time" :readonly="!newLicenseForm" v-if="editedItem.preactivationMode == 1 && editedItem.vpnAccountingDisabled == 0" v-model="editedItem.vpnStartDateTime" :hint="actualVpnStartDateTime"  :error-messages="startDateTimeErrors" label="VPN start ISO date time (optional)"></v-text-field>
                    <v-text-field data-qa="vpn-end-date-time" :readonly="!newLicenseForm" v-if="editedItem.preactivationMode == 1" v-model="editedItem.vpnEndDateTime"  :hint="actualVpnEndDateTime" :error-messages="endDateTimeErrors" label="VPN end ISO date time (optional)"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
  
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn data-qa="cancel-new-license" color="blue darken-1" text @click.native="close">Cancel</v-btn>
              <v-btn data-qa="save-new-license" color="blue darken-1" text @click.native="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table 
            :headers="headers"
            :items="licenses"
            :loading="loading"
            :options.sync = "pagination"
            :server-items-length="totalLicenses"
            mobile-breakpoint="800"
            :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, 200]
            }">
            <template slot="no-data">
            <v-alert :value="true" color="error" icon="warning">
            No license found
            </v-alert>
            </template>

            <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        @click="editItem(item)"
                        >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                        >
                        mdi-delete
                    </v-icon>
            </template>
             
            <template slot="item" slot-scope="{ item }">
                <tr class="class">
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.id }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.realm }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.orgResourceId }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.activationKey }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.logicalId }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.label }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.clientName }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.notes }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.alias }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.serialNumber }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.apiKey }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" > {{ 
                    item.vpnValidityMonths 
                        ? `${item.vpnValidityMonths} months` 
                        : ( item.fromDateVpn  
                            ? `[ ${ !item.vpnAccountingDisabled ? item.fromDateVpn : '-'} , ${ item.toDateVpn } ]` 
                            : "" + ( item.numOfMonthsAutoRenewVpn 
                                ? `x ${ item.numOfMonthsAutoRenewVpn }` 
                                : '' ) )}} <span style="font-weight: bold; color: red"> {{ item.vpnAccountingDisabled ? " / NO ACCOUNTING" : "" }}</span></td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.vpnKey }}</td>
                <td class="text-left" :class="{deleted : item.deleted , used : item.used }" >{{ item.usedInTrial }}</td>
                <td>
                    <v-icon
                        small
                        @click="editItem(item)"
                        >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                        >
                        mdi-delete
                    </v-icon>
                </td>
                </tr>
            </template>
        </v-data-table>


        <!-- Modal for displaying error -->
        <v-dialog v-model="showErrorModal" max-width="500">
        <v-card>
            <v-card-title class="headline error-title">Error</v-card-title>
            <v-card-text class="error-text">{{ errorMessage }}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="showErrorModal = false">Close</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>


    </div>
</template>

<script lang="ts">

  import LicenseManagerAxiosInstance from '../communication/axios/implementation/LicenseManagerAxiosInstance'
  import { FetchOptions } from '../communication/axios/ILicenseManagerAxiosInstance'
  import { v4 as uuidv4 } from 'uuid';
  const toLower = text => {
    return text.toString().toLowerCase()
  }

import { Vue, Component, Watch } from 'vue-property-decorator'

interface LicenseData  {

}

enum PreactivationMode {
    RELATIVE = 0,
    ABSOLUTE = 1
}

const defaultItem = { preactivationMode: PreactivationMode.RELATIVE, vpnAccountingDisabled: false, vpnStartDateTime: null, vpnEndDateTime: null, vpnValidityMonths: 36 };

// The @Component decorator indicates the class is a Vue component
@Component({
})
export default class LicenseView extends Vue{
    search: string = ''
    dialog: boolean = false
    loading: boolean = true
    totalLicenses: number = 0

    showErrorModal: boolean = false
    errorMessage: string = ''
    showBanner: boolean = true

    newLicenseModal: boolean = false
    latestLicenses: any = [{}]

    editedIndex: number = -1
    editedItem: any = { ...defaultItem }
    pagination: any = { page: 1, itemsPerPage: 10 }
    headers: any[] = [
        {
            text: 'ID',
            align: 'left',
            sortable: true,
            value: 'id'
        },
        {
            text: 'Realm',
            align: 'left',
            sortable: true,
            value: 'realm'
        },
        {
            text: 'Organization',
            align: 'left',
            sortable: true,
            value: 'orgResourceId'
        },
        {
            text: 'Activation key',
            align: 'left',
            width: '200px',
            sortable: true,
            value: 'activationKey'
        },
        {
            text: 'Logical ID',
            align: 'left',
            sortable: true,
            value: 'logicalId'
        },
        {
            text: 'Device Name',
            align: 'left',
            sortable: true,
            value: 'label'
        },
        {
            text: 'Customer',
            align: 'left',
            sortable: true,
            value: 'clientName'
        },
        {
            text: 'Notes',
            align: 'left',
            width: '200px',
            sortable: true,
            value: 'notes'
        },
        {
            text: 'Device Alias',
            align: 'left',
            sortable: true,
            value: 'alias'
        },
        {
            text: 'Serial No.',
            align: 'left',
            sortable: true,
            value: 'serialNumber'
        },
        {
            text: 'API Key',
            align: 'left',
            sortable: true,
            value: 'apiKey'
        },
        {
            text: 'VPN',
            align: 'left',
            sortable: true
        },
        {
            text: 'VPN Key',
            align: 'left',
            sortable: true,
            value: 'vpnKey'
        },
        {
            text: 'Used in trial',
            align: 'left',
            sortable: false,
            value: 'usedInTrial'
        },
        { 
            text: 'Actions',
            value: 'actions',
            sortable: false
        }     
    ]
    licenses: LicenseData[] = []
    latestLicense: LicenseData[] = []

    get newLicenseForm() {
        return this.editedIndex === -1
    }

    get formTitle () {
        return this.editedIndex === -1 ? 'New License' : 'Edit Item'
    }

    get startDateTimeErrors() : string[] {
        if (!this.editedItem.vpnStartDateTime && !this.editedItem.vpnEndDateTime) return [];
        try {
            if (isNaN((new Date(this.editedItem.vpnStartDateTime)).valueOf())) {
                throw new Error("Invalid date")
            }
        } catch(e) {
            return [e.message]
        }
        return [];
    }

    get actualVpnStartDateTime() : string {
        try {
            return this.editedItem.vpnStartDateTime ? new Date(this.editedItem.vpnStartDateTime).toISOString() : null
        } catch(e) {
            return null
        }
    }

    get endDateTimeErrors() : string[]{
        if (!this.editedItem.vpnStartDateTime && !this.editedItem.vpnEndDateTime) return [];
        try {
            if (isNaN((new Date(this.editedItem.vpnStartDateTime)).valueOf())) {
                throw new Error("Invalid date")
            }
            if (this.editedItem.vpnStartDateTime && !isNaN((new Date(this.editedItem.vpnStartDateTime)).valueOf() )) {
                if ( (new Date(this.editedItem.vpnStartDateTime)).valueOf() > (new Date(this.editedItem.vpnEndDateTime)).valueOf() ) {
                    throw new Error("End date must be after start date")
                }
            }
        } catch(e) {
            return [e.message]
        }
        return [];
    }

    get actualVpnEndDateTime() : string {
        try {
            return this.editedItem.vpnEndDateTime ? new Date(this.editedItem.vpnEndDateTime).toISOString() : null
        } catch(e) {
            return null
        }
    }

    @Watch('pagination', {deep: true})
    onPaginationChanged(val, oldVal) {
        this.refresh()
    }

    @Watch('search')
    onSearchChanged(val, oldVal) {
            this.pagination.page = 1;
            this.refresh();
    }

    async refresh() {
        const sortByItem = (this.pagination.sortBy && this.pagination.sortBy.length) ? this.pagination.sortBy[0] : undefined
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination

        try {
            await this.$router.replace({ query: { filter: this.search, itemsPerPage: this.pagination.itemsPerPage, sortBy: this.pagination.sortBy, sortDesc: this.pagination.sortDesc, page: this.pagination.page} })
        } catch(err) {
            console.log("Error pushing new url: " , err)
        }
        const pagedData = await LicenseManagerAxiosInstance.fetchLicenses({
            page: page == 0 ? page : page - 1, 
            pageSize: itemsPerPage,
            filter: this.search,
            orderBy: sortByItem,
            orderDir: (sortDesc && sortDesc[0]) ? 'DESC' : 'ASC'

        } as FetchOptions )
        //this.totalLicenses = pagedData.totalElements
        this.licenses = pagedData.content
        this.loading = false;
        this.licenses = pagedData.content
        this.totalLicenses = pagedData.totalElements
    }

    async generateLicense(editedItem: {  serialNumber: string, clientName: string, notes: string, copies: number, vpnStartDateTime: string, vpnEndDateTime: string, preactivationMode: PreactivationMode, vpnValidityMonths: number, vpnAccountingDisabled: boolean }) {

        let {serialNumber, clientName, notes, copies, preactivationMode, vpnValidityMonths, vpnStartDateTime, vpnEndDateTime} = editedItem;

        console.log("copies: " + copies)
        if (!copies || copies <= 0) {
            copies = 1
        }
        this.loading = true;
        this.latestLicenses.splice(0)
        let promises = []
        let vpnStartDateIso = undefined;
        let vpnEndDateIso = undefined;
        let vpnAccountingDisabled = undefined;
        try {
            if (preactivationMode == PreactivationMode.RELATIVE) {
                if (vpnValidityMonths) {
                    vpnStartDateTime = undefined;
                    vpnEndDateTime = undefined;
                    vpnAccountingDisabled = editedItem.vpnAccountingDisabled;
                }
            } else if (preactivationMode == PreactivationMode.ABSOLUTE) {
                vpnValidityMonths = undefined;
                vpnAccountingDisabled = editedItem.vpnAccountingDisabled;
                if (vpnStartDateTime) vpnStartDateIso = (new Date(vpnStartDateTime)).toISOString();
                if (vpnAccountingDisabled) {
                    vpnAccountingDisabled = true;
                    vpnStartDateIso = (new Date(1)).toISOString();            
                }
                if (vpnEndDateTime) vpnEndDateIso = (new Date(vpnEndDateTime)).toISOString();
            }
        } catch(e) {}
        for(let i =0; i< copies; i++) {
            promises.push(   LicenseManagerAxiosInstance.createLicense( serialNumber, ""/* BigInt('0x'+ uuidv4().replace(/-/g,'')).toString(36).match(/.{5,5}/g).slice(0,4).join('-').toUpperCase()*/, clientName, notes, vpnValidityMonths, vpnAccountingDisabled, vpnStartDateIso, vpnEndDateIso ) )
        }
        try {
            this.latestLicenses = await Promise.all(promises)
            console.log(this.latestLicenses)
            this.newLicenseModal = true
            this.refresh();
        } catch(error) {
            console.log("Error generating license: " , error)
            this.errorMessage = error.response ? error.response.data.message : error.message;
            this.showErrorModal = true;
        } finally {
            this.loading = false;
        }
    }

    newLicense() {
        this.editedIndex = -1
        this.dialog = true
    }

    editItem (item) {
        this.editedIndex = this.licenses.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editedItem.vpnStartDateTime = new Date(this.editedItem.fromDateVpn)
        this.editedItem.vpnEndDateTime = new Date(this.editedItem.toDateVpn)
        this.editedItem.preactivationMode = this.editedItem.vpnValidityMonths ? PreactivationMode.RELATIVE : PreactivationMode.ABSOLUTE
        this.dialog = true
    }

    async deleteItem (item) {
        if ( confirm('Are you sure you want to delete this item?') ) {
            this.loading = true;
            await LicenseManagerAxiosInstance.deleteLicense( item.id )
            this.refresh();
        }
    }

    close () {
        this.dialog = false
        setTimeout(() => {
            this.editedItem = Object.assign({}, defaultItem)
            this.editedIndex = -1
        }, 300)
    }

    async save () {
        if (this.editedIndex > -1) {
            // edit existing
            this.loading = true;
            this.latestLicense.splice(0)
            this.latestLicense.push( await LicenseManagerAxiosInstance.editLicense( this.editedItem.id, this.editedItem.serialNumber, this.editedItem.clientName, this.editedItem.notes ) )
            this.refresh();
        } else {
            // create new
            this.generateLicense(this.editedItem);
        }
        /* if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
            this.desserts.push(this.editedItem)
        } */
        this.close()
    }
    
    mounted () {
        if (this.$router.currentRoute.query.filter) 
            this.search = this.$router.currentRoute.query.filter.toString()
        if (this.$router.currentRoute.query.itemsPerPage) 
            this.pagination.itemsPerPage = parseInt(this.$router.currentRoute.query.itemsPerPage.toString())
        if (this.$router.currentRoute.query.sortBy) 
            this.pagination.sortBy = [ this.$router.currentRoute.query.sortBy.toString() ]
        if ( this.$router.currentRoute.query.sortDesc) 
            this.pagination.sortDesc = [ this.$router.currentRoute.query.sortDesc.toString() == 'true' ? true : false ]
        if (this.$router.currentRoute.query.page) 
            this.pagination.page = parseInt(this.$router.currentRoute.query.page.toString())
    }
}
</script>

<style scoped>
  .md-field {
    max-width: 300px;
  }
  .deleted {
      text-decoration: line-through
  }
  .used {
      font-weight: bold
  }
  .error-banner {
    color: white;
  }

  >>> .v-banner__wrapper {
    background-color: #b30000;
    border: 1px solid #b30000;
  }

  .error-modal {
    border: 2px solid red;
    background-color: #ffe6e6;
    padding: 20px;
  }

  .error-title {
    color: red;
 }

  .error-text {
    color: #b30000;
  }
</style>
